import React, {useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'

import Context from './context.jsx'

import ajaxRequest from '../../assets/functions/ajaxRequest.js'
import XIcon from '../shared/icons/xIcon.jsx'

export default function PhoneVerifyModal(props) {
  const {authenticityToken, getConsumer, buttonTextColor} = useContext(Context)

  const verifyCodeLength = 6

  const history = useHistory()

  const [phoneNumber, setPhoneNumber] = useState('')
  const [verifyCode, setVerifyCode] = useState('')

  const [codeSent, setCodeSent] = useState(false)

  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false)
  const [phoneNumberTaken, setPhoneNumberTaken] = useState(false)
  const [failedToVerify, setFailedToVerify] = useState(false)

  const handlePhoneNumberChange = (event) => {
    var input = event.target.value
    var regex = /[\d-]+/

    if (input === '' || regex.test(input[input.length - 1])) {
      if (input.length <= phoneNumber.length) {
        // erasing
        setPhoneNumber(input)
      } else {
        // typing
        if (input.length === 3 || input.length === 7)
          setPhoneNumber(input.replace(/$/, '-'))
        else setPhoneNumber(input)
      }
    }
  }

  const handleSendCode = (event) => {
    event.preventDefault()

    setPhoneNumberInvalid(false)
    setPhoneNumberTaken(false)
    if (phoneNumber.length !== 12) return setPhoneNumberInvalid(true)

    ajaxRequest('POST', '/consumer_phone', {
      params: {phone_number: phoneNumber},
      headers: [{'X-CSRF-Token': authenticityToken}],
    })
      .then((response) => {
        setCodeSent(true)
      })
      .catch((response) => {
        const errors = response.body.errors

        if (!errors) setPhoneNumberInvalid(true)

        errors.forEach((error) => {
          if (error === 'Phone number is invalid') {
            setPhoneNumberInvalid(true)
          } else if (error === 'Phone number has already been taken') {
            setPhoneNumberTaken(true)
          } else {
            setPhoneNumberInvalid(true)
          }
        })
      })
  }

  const handleVerifyPhone = (event) => {
    event.preventDefault()

    setFailedToVerify(false)
    if (verifyCode.length !== verifyCodeLength) return setFailedToVerify(true)

    ajaxRequest('POST', '/consumer_phone', {
      params: {phone_number: phoneNumber, code: verifyCode},
      headers: [{'X-CSRF-Token': authenticityToken}],
    })
      .then((response) => {
        getConsumer()
        props.close()
        history.push('/')
      })
      .catch((response) => {
        setFailedToVerify(true)
      })
  }

  return (
    <div
      className={`${
        codeSent ? 'code-sent ' : ''
      }z-100 fixed top-0 right-0 bottom-0 left-0 overflow-auto w-screen h-screen bg-black bg-opacity-80`}
      data-visible={props.open}>
      <div className='rounded md:p-24 mx-auto flex items-center justify-center min-h-screen max-w-4xl'>
        <div className='bg-white text-dark w-full m-3'>
          <div className='bg-secondary text-white p-4 font-bold relative'>
            <h2 className='md:w-full mr-8 text-xl'>
              Add a phone number to verify your account
            </h2>

            <div className='absolute top-0 right-0 p-4 text-white'>
              <button onClick={() => props.close()}>
                <XIcon />
              </button>
            </div>
          </div>

          <form className='p-4' onSubmit={handleVerifyPhone}>
            <fieldset>
              <legend className='md:w-3/5 mb-4 mx-auto font-normal'>
                <p>Please enter a phone number to verify your account.</p>
                <p>This phone number will be used to cash out with Venmo.</p>
              </legend>

              <div className='mb-4 mx-auto max-w-md'>
                <label
                  htmlFor='phoneNumber'
                  className='inline-block font-bold text-dark pr-2 w-1/3 text-right'>
                  Phone number
                </label>

                <input
                  className='rounded outline-none focus:ring-1 focus:ring-light border border-gray-300 p-1 w-1/2 text-dark'
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  disabled={codeSent}
                  maxLength='12'
                  id='phoneNumber'
                  name='phoneNumber'
                  type='tel'
                  autoComplete='off'
                />
              </div>

              {phoneNumberInvalid ? (
                <p className='mb-4 font-sm font-normal text-red-700'>
                  Phone number is not valid.
                </p>
              ) : (
                <></>
              )}
              {phoneNumberTaken ? (
                <p className='mb-4 font-sm font-normal text-red-700'>
                  Phone number has already been taken.
                </p>
              ) : (
                <></>
              )}

              {codeSent ? (
                <>
                  <div className='mb-4 mx-auto max-w-md'>
                    <label
                      htmlFor='email-override'
                      className='inline-block font-bold text-dark pr-2 w-1/3 text-right'>
                      Code
                    </label>

                    <input
                      className='rounded outline-none focus:ring-1 focus:ring-light border border-gray-300 p-1 w-1/2 text-dark'
                      value={verifyCode}
                      onChange={(event) => setVerifyCode(event.target.value)}
                      maxLength={verifyCodeLength}
                      id='verifyCode'
                      name='verifyCode'
                      type='tel'
                      autoComplete='off'
                    />
                  </div>
                </>
              ) : (
                <></>
              )}

              {failedToVerify ? (
                <p className='mb-4 font-sm font-normal text-red-700'>
                  Failed to verify. Please try again.
                </p>
              ) : (
                <></>
              )}
            </fieldset>

            {codeSent ? (
              <button
                disabled={!(verifyCode.length === verifyCodeLength)}
                className={`block w-full sm:w-64 mx-auto p-2 rounded-full transition duration-300 bg-primary text-center ${buttonTextColor} hover:shadow ${
                  verifyCode.length === verifyCodeLength ? '' : 'opacity-30'
                }`}>
                Submit
              </button>
            ) : (
              <button
                onClick={handleSendCode}
                className={`block w-full sm:w-64 mx-auto p-2 rounded-full transition duration-300 bg-primary text-center ${buttonTextColor} hover:shadow`}>
                Get Code
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
